import React from 'react'
import { Typography } from '@material-ui/core'
import Container from '@material-ui/core/Container'
import Divider from '@material-ui/core/Divider'
import Layout from '../components/layout'
import SEO from '../components/seo'
import {ServerTableShack} from '../components/Tables/table'

const IndexPage = () => {

  const pageTitle = "Shack Server List"

  const pageDesc = "List of custom servers for Pavlov Shack VR. Select your server row to directly connect via RCON tool!"

  return (
  <Layout pageTitle={pageTitle}>
    <SEO desc={pageDesc} title={pageTitle} />
      <Container>
      <Typography variant="h3" style={{ color: "white" }}>
          Pavlov Servers
          </Typography>
          <Typography variant="body1" style={{ color: "#f50057" }}>
          Horde offers servers and tools for the Meta Quest version of Pavlov (Shack)
          </Typography>
      </Container>
      <ServerTableShack/>
      <Divider />

    </Layout>
  )
}

export default IndexPage
